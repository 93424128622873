import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import {
  faBars,
  faBullhorn,
  faCalendar,
  faDashboard,
  faEdit,
  faGavel,
  faMoneyBills,
  faPerson,
  faPhone,
  faPlus,
  faTrash,
  faTrophy,
  faUsers,
  faVideo,
  faUser,
  faMapLocation,
  faDraftingCompass,
  faBed,
  faShower,
  faSearch,
  faMagnifyingGlass,
  faChevronLeft,
  faChevronRight,
  faMapMarker,
  faLocationDot,
  faTag,
  faEnvelope,
  faCaretRight,
  faHome,
  faFilter,
  faTimes,
  faChevronUp,
  faBarcode,
  faCheck,
  faHandshake,
  faCoffee,
  faGem,
  faBriefcase,
  faHammer,
  faSink,
  faTree,
  faCertificate,
  faPeopleGroup,
  faThumbsUp,
  faTrowelBricks,
  faPaintRoller,
  faPeopleRoof,
  faBolt,
  faWrench,
  faLightbulb,
  faTools,
  faClock,
  faSmile,
  faTasks,
  faExpand,
  faHardHat,
  faDoorOpen,
  faWater,
  faPlug,
  faToilet,
  faComments,
  faClipboardCheck,
  faAt,
  faLock,
  faShield,
  faUserShield,
  faHeart,
  faChevronDown,
  faExclamationTriangle,
  faShoppingCart,
  faShoppingBag,
  faDog,
} from '@fortawesome/free-solid-svg-icons';

const calendarIcon = faCalendar;
const dashboardIcon = faDashboard;
const moneyBillsIcon = faMoneyBills;
const personIcon = faPerson;
const barsIcon = faBars;
const usersIcon = faUsers;
const plusIcon = faPlus;
const trashIcon = faTrash;
const editIcon = faEdit;
const videoIcon = faVideo;
const gavelIcon = faGavel;
const bullhornIcon = faBullhorn;
const trophyIcon = faTrophy;
const phoneIcon = faPhone;
const instaIcon = faInstagram;
const userIcon = faUser;
const mapLocationIcon = faMapLocation;
const draftingCompassIcon = faDraftingCompass;
const bedIcon = faBed;
const showerIcon = faShower;
const searchIcon = faSearch;
const magnifyingGlassIcon = faMagnifyingGlass;
const chevronLeftIcon = faChevronLeft;
const chevronRightIcon = faChevronRight;
const chevronDownIcon = faChevronDown;
const chevronUpIcon = faChevronUp;
const mapMarkerIcon = faMapMarker;
const locationDotIcon = faLocationDot;
const tagIcon = faTag;
const envelopeIcon = faEnvelope;
const caretRightIcon = faCaretRight;
const homeIcon = faHome;
const filterIcon = faFilter;
const timesIcon = faTimes;
const barCodeIcon = faBarcode;
const checkIcon = faCheck;
const gemIcon = faGem;
const handshakeIcon = faHandshake;
const coffeeIcon = faCoffee;
const fbIcon = faFacebook;
const briefCaseIcon = faBriefcase;
const hammerIcon = faHammer;
const sinkIcon = faSink;
const treeIcon = faTree;
const certificateIcon = faCertificate;
const peopleGroupIcon = faPeopleGroup;
const thumbsUpIcon = faThumbsUp;

const trowelBricksIcon = faTrowelBricks;
const paintRollerIcon = faPaintRoller;
const peopleRoofIcon = faPeopleRoof;
const boltIcon = faBolt;
const wrenchIcon = faWrench;
const lightBulbIcon = faLightbulb;
const toolsIcon = faTools;
const linkedInIcon = faLinkedin;
const clockIcon = faClock;
const smileIcon = faSmile;
const tasksIcon = faTasks;
const expandIcon = faExpand;
const hardHatIcon = faHardHat;
const doorOpenIcon = faDoorOpen;
const waterIcon = faWater;
const plugIcon = faPlug;
const toiletIcon = faToilet;
const commentsIcon = faComments;
const clipboardCheckIcon = faClipboardCheck;
const atIcon = faAt;
const lockIcon = faLock;
const shieldIcon = faShield;
const userShieldIcon = faUserShield;
const heartIcon = faHeart;
const exclamationTriangleIcon = faExclamationTriangle;
const shoppingCartIcon = faShoppingCart;
const shoppingBagIcon = faShoppingBag;
const dogIcon = faDog;

export {
  calendarIcon,
  dashboardIcon,
  moneyBillsIcon,
  personIcon,
  barsIcon,
  usersIcon,
  plusIcon,
  trashIcon,
  editIcon,
  videoIcon,
  gavelIcon,
  bullhornIcon,
  trophyIcon,
  phoneIcon,
  instaIcon,
  userIcon,
  mapLocationIcon,
  draftingCompassIcon,
  bedIcon,
  showerIcon,
  searchIcon,
  magnifyingGlassIcon,
  chevronLeftIcon,
  chevronRightIcon,
  chevronUpIcon,
  mapMarkerIcon,
  locationDotIcon,
  tagIcon,
  envelopeIcon,
  caretRightIcon,
  homeIcon,
  filterIcon,
  timesIcon,
  barCodeIcon,
  checkIcon,
  gemIcon,
  handshakeIcon,
  coffeeIcon,
  fbIcon,
  briefCaseIcon,
  hammerIcon,
  sinkIcon,
  treeIcon,
  certificateIcon,
  peopleGroupIcon,
  thumbsUpIcon,
  trowelBricksIcon,
  paintRollerIcon,
  peopleRoofIcon,
  boltIcon,
  wrenchIcon,
  lightBulbIcon,
  toolsIcon,
  linkedInIcon,
  clockIcon,
  smileIcon,
  tasksIcon,
  expandIcon,
  hardHatIcon,
  doorOpenIcon,
  waterIcon,
  plugIcon,
  toiletIcon,
  commentsIcon,
  clipboardCheckIcon,
  atIcon,
  lockIcon,
  shieldIcon,
  userShieldIcon,
  heartIcon,
  chevronDownIcon,
  exclamationTriangleIcon,
  shoppingCartIcon,
  shoppingBagIcon,
  dogIcon,
};
